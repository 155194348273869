import React, { useEffect } from 'react'
import Head from 'next/head'
import Link from 'next/link'
import { MAIN_TITLE } from '../config'
import Layout from '../layout/base'
import Content from '../layout/content'
import Icon from '../components/Icon'
import { useRouter } from 'next/router'

export default function Index() {
  const router = useRouter()

  useEffect(() => {
    router.push('/potentials/results')
  }, [])

  return (
    <React.Fragment>
      <Head>
        <title>Index - {MAIN_TITLE}</title>
      </Head>
      <Layout title="Rendl">
        <Content>
          <div className="dashboard">
            <div className="dashboard-item dashboard-color-potentials">
              <div className="dashboard-item-icon">
                <Icon code="users" title="Potentials" size="48"></Icon>
              </div>
              <div className="dashboard-item-links">
                <Link href="/potentials/results">
                  <Icon code="potentials" title="Potentials" />
                  <span>Potentials</span>
                </Link>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </React.Fragment>
  )
}

Index.requireAuth = true
